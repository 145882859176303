const assets = {
    drop: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fdrop-icon.svg?alt=media&token=66de9bfd-4ec5-4cb3-8088-5ffcb94bde1d',
    config:'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fconfig-icon.png?alt=media&token=76a2f978-d5dc-4176-8280-5d200b06041d',
    user: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fuser-svgrepo-com.png?alt=media&token=c5155125-c909-423e-ace7-00d27adffa57',
    user2: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fuser2.png?alt=media&token=4de9e729-a5fc-4a1b-886c-8838838b2a7b',
    user3: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fuser-profile-svgrepo-com.png?alt=media&token=089a083f-55cf-4958-9ae4-0e3516ad4104',
    react: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Freact-svgrepo-com.png?alt=media&token=b67eecf0-c9cb-4909-b36d-7a20b728c763',
    reactBlack: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Freact-logo-programming-2-svgrepo-com.png?alt=media&token=20228bf3-8827-4683-9d4e-95f8a7851120',
    money: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fmoney-svgrepo-com%20(1).png?alt=media&token=d02dc9ff-5c35-4a0a-8cdf-af347ea1f84f',
    gold: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fgold-img.png?alt=media&token=2daaa669-852a-477b-aae2-726b2c79f48d',
    dropdownNav: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fdropdown-svgrepo-com.png?alt=media&token=37dd5603-a768-408f-97c0-79d796e745b8',
    dropdownNavBLACKMODE: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fdropdown-arrow-BLACKMODE.png?alt=media&token=917c30f7-c544-4259-ad0f-80337cad6e36',
    plus: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fplus-svgrepo-com.png?alt=media&token=6f1bfb41-cda1-462e-b2e5-406f37592a3a',
    saque: "https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Farrow-bottom-svgrepo-com.png?alt=media&token=75c1977d-6a4c-47b5-a24e-13f391c1535a",
    download: 'https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fdownload-minimalistic-svgrepo-com.png?alt=media&token=162597e2-b085-4688-b6ce-0116fc4ff827',
    oftAsk: "https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fquestions-help-svgrepo-com.png?alt=media&token=1febd18b-46ee-4838-a7a4-dfc6f589cbf4",
    help: "https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fhelp-svgrepo-com.svg?alt=media&token=05b70945-1471-49df-a463-7bd90078bba1",
    menuBlue: "https://firebasestorage.googleapis.com/v0/b/white-lable-528b0.appspot.com/o/assets%2Fmenu-icon-blue.png?alt=media&token=7450779f-3169-436f-9076-fa5a36540c67",
    dashSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/dash_svg_icon.png?alt=media&token=0f52ca22-4ab8-4292-8ea9-e25ec66d2750',
    downloadSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/download_svg_icon.svg?alt=media&token=cf3bc35e-5c7c-4067-99e1-408c26289d4c',
    infoSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/info_svg_icon.png?alt=media&token=67809b7c-0231-4bcb-ba35-37187d7ae5d8',
    newsSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/news_svg_icon.svg?alt=media&token=922d7f1b-a1d1-475e-8110-ee15110750d3',
    saqueSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/saque_svg_icon.svg?alt=media&token=e05d4565-0973-4aed-b783-c27623552f1f',
    tokenSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/token_svg_icon.png?alt=media&token=accc5a7f-82c2-456f-9252-18bc42cb505d',
    helpSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/support-female-svgrepo-com.png?alt=media&token=f40f774a-ef7d-4766-acf7-c7b0a485bc57',
    docSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/doc-success-svgrepo-com.png?alt=media&token=cde378d2-4c65-464f-b437-22e533c3ed63',
    extratoSvg: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/sign-out-alt-2-svgrepo-com.svg?alt=media&token=8f067c18-2021-4ebf-971a-ece342dc41dd',
    caiuaLogo: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/logo-removebg-preview.png?alt=media&token=ae9d6dbc-e839-4f2c-91dc-b000843a1aac',
    imageBrandPlatform: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/logoGoldenToken-removebg-preview.png?alt=media&token=5109abf4-41b8-4c9a-8803-4b4adbab0cb2',
    payIcon: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/down-square-3-svgrepo-com.png?alt=media&token=86a34f5a-ad8f-41c3-b1e1-479b0fd97250',
    sidebarMenu: 'https://firebasestorage.googleapis.com/v0/b/golden-token-62a99.appspot.com/o/ICONS%2Fmenu-alt-2-svgrepo-com.png?alt=media&token=77475c95-1cb5-445a-b2bd-19b189d8ad54',
    carteiraFechada: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/carteiraFechada.png?alt=media&token=38118eb4-495d-4b87-bc44-da402cfbf936',
    carteiraAberta: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/carteiraAberta.png?alt=media&token=3e9dafab-46ff-407c-856a-8a7e8c3a2493',
    pulseImage: 'https://firebasestorage.googleapis.com/v0/b/wldata.appspot.com/o/logoGoldenToken-removebg-preview.png?alt=media&token=5109abf4-41b8-4c9a-8803-4b4adbab0cb2'
};

export default assets;
